import store from "@/store/index.js";

export const retomarCotizacion = (item) => {
  const params = {},
    query = {};
  let name = "dashboard";

  return new Promise((resolve, reject) => {
    const estado = item?.estado ?? "",
      nroPropuesta = item?.numero_propuesta;

    // Condiciones para tipo seleccionada y falta de informacion
    if (estado === "FAL" || estado === "SEL") {
      // Condicion de rechazo si no hay numero de propuesta
      if (!nroPropuesta) {
        reject(
          Error("No se ha encontrado el numero de propuesta en la cotización")
        );
      }
    }

    // Establecer la informacion del cliente
    store.dispatch("Users/setClient", {
      document_number: item.numero_documento,
      document_type: item.tipo_documento,
    });

    // Retomar propuesta creada en una cotizacion
    if (estado === "FAL") {
      // Contizacion de tipo Falta de informacion
      name = "Contract";
      params.id = nroPropuesta;
      query.paso = item?.step ?? 1;
    } else if (estado === "SEL") {
      // Cotizacion de tipo seleccionada
      name = "ShoppingCart";
      params.propuesta = nroPropuesta;
    } else if (estado === "A") {
      // Cotizacion de tipo Abierta
      name = "quotationOffers";
      params.id = item.numero_cotizacion;
    }

    resolve({
      name,
      params,
      query,
    });
  });
};

export const estapaCotizacion = (item = null) => {
  let value = "",
    color = "",
    icon = "";
  const { paso, estado } = item;
  switch (estado) {
    case "FAL": {
      color = "success";
      switch (paso) {
        case 1:
        case "1": {
          value = "Info. Asegurado";
          icon = "account";
          break;
        }
        case 2:
        case "2": {
          value = "Info. Bien";
          icon = "car-wireless";
          break;
        }
        case 3:
        case "3": {
          value = "Requisitos";
          icon = "view-list-outline";
          break;
        }
        case 4:
        case "4": {
          value = "Forma de Pago";
          icon = "credit-card-check-outline";
          break;
        }
        case 5:
        case "5": {
          value = "Firma";
          icon = "draw-pen";
          break;
        }
        default: {
          value = "Contratación";
          icon = "file-document-outline";
          break;
        }
      }
      break;
    }
    case "A": {
      color = "primary";
      value = "Ofertas";
      icon = "tag-multiple-outline";
      break;
    }

    case "SEL": {
      color = "warning";
      value = "Carrito de Compra";
      icon = "cart-arrow-down";
      break;
    }
  }
  return { value, icon, color };
};

export default {
  retomarCotizacion,
  estapaCotizacion,
};
